export const bidsCustomQueryFields = (suppliers) => {
  const bidsFields = {
    auction: {
      label: 'Auction',
      type: '!struct',

      subfields: {
        bids: {
          label: 'Bids',
          type: '!group',
          mode: 'array',
          subfields: {
            supplier: {
              label: 'Supplier',
              type: '!struct',
              subfields: {
                internalName: {
                  label: 'InternalName',
                  type: 'select',
                  valueSources: ['value'],
                  fieldSettings: {
                    listValues: suppliers
                      .map((supp) => {
                        if (supp.internalName && supp.name) {
                          return {
                            value: supp.internalName,
                            title: supp.name,
                          };
                        }
                      })
                      .reverse()
                      .filter(Boolean),
                  },
                },
              },
            },
            accepted: {
              label: 'Possui bid aceito',
              type: 'boolean',
              valueSources: ['value'],
            },
            created: {
              label: 'Data criação - Bids',
              type: 'datetime',
              operators: ["equal", "not_equal", "between", "not_between", "less", "less_or_equal", "greater", "greater_or_equal",],
              valueSources: ['value','func', 'field'],
              sqlOp: '=',
              valueLabel: 'Data dos bids',
              returnType: 'time',
            },
            rejected: {
              label: 'Possui rejected',
              type: 'boolean',
              valueSources: ['value'],
            },
          },
        },
      },
    },
  };

  return bidsFields;
};

export const customQueryFields = {
  personalNumber7Digit: {
    label: 'Sétimo digito do CPF',
    type: 'number',
    valueSources: ['value'],
    fieldSettings: {
      min: 0,
      max: 9,
    },
  },
  //hash de Login mantido para fallback de versões antigas
  hashNumberLogin: {
    label: 'Hash de login',
    type: 'number',
    valueSources: ['value'],
    fieldSettings: {
      min: 0,
      max: 10,
    },
  },
  hashNumberABTest: {
    label: 'Hash de Teste AB',
    type: 'number',
    valueSources: ['value'],
    fieldSettings: {
      min: 0,
      max: 100,
    },
  },
};
